import React from "react"
import RoundTracker from "./components/RoundTracker";

export default function RoomLayoutWrapper({roundNumber, children}){
  return (
    <div className="canary room-view">
      <div className="wipe-offset">
        <RoundTracker/>
        {children}
      </div>
      <div className="wipe"></div>
    </div>
  );
}

export function Header({text}){
  return (
    <h1 className="system-text">{text}</h1>
  )
}

export function DescriptionWrapper({children}){
  return (
    <div className="width-limit">
      {children}
    </div>
  )
}