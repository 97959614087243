import React from "react";

import useViewContext from "../context/context";
import {tieGifs, fightGifs} from "../context/gifs";
import { syncRandomItemWithSeed } from "common/utils";

export default function Tie(){
  const {viewProps} = useViewContext()
  const {winner_id, is_tie_breaker, prompt} = viewProps;

  if (!winner_id || !is_tie_breaker) return null;
  const screenGif1 = syncRandomItemWithSeed(tieGifs, prompt.id);
  const screenGif2 = syncRandomItemWithSeed(fightGifs, prompt.id);
  return (
    <div className="tie">
      <div
        className="screen-1 full-screen-image "
        style={{ backgroundImage: `url(${screenGif1})` }}
      >
        <div className="big-center-text">TIE?</div>
      </div>
      <div
        className="screen-2 full-screen-image "
        style={{ backgroundImage: `url(${screenGif2})` }}
      >
        <div className="big-center-text">
          <div>NO</div>
          <div>WAY</div>
        </div>
      </div>
    </div>
  );
}