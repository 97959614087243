import React from "react"

import useViewContext, { ViewProvider } from "./context/context";
import RoomLayout from "./layout"
import { SUB_VIEWS } from "./context/constants";
import LoginView, { LoginChoiceView } from "./LoginView.js/LoginView";
import AuthedView from "./AuthedView/AuthedView";

export default function WrappedView(props) {
  return (
    <ViewProvider>
      <View />
    </ViewProvider>
  );
}

function View() {
  const {room, subView} = useViewContext();
  const SubView = SUB_VIEW_COMPONENTS[subView];
  return (
    <RoomLayout code={room?.code}>
      <SubView />
      <Errors />
    </RoomLayout>
  );
}

const SUB_VIEW_COMPONENTS = {
  [SUB_VIEWS.LOGIN]: LoginView,
  [SUB_VIEWS.LOGIN_CHOICE]: LoginChoiceView,
  [SUB_VIEWS.AUTHED]: AuthedView,
};

function Errors() {
  const { errors } = useViewContext();
  if (!errors.length) return null;

  return (
    <div>
      Errors:
      <ul>
        {errors.map(error=>(
          <li>{error}</li>
        ))}
      </ul>
    </div>
  )
}