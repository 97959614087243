import {gql, useMutation} from '@apollo/client';


export default function useStartGame(params){
  const {stateRef} = params;
  
  const [mutate] = useMutation(CREATE_GAME)

  function startGame(){
    const {id:roomId} = stateRef.current.room;

    const variables = {
      roomId,
    }
    mutate({variables});
  }

  return startGame
}

const CREATE_GAME = gql`
  mutation canaryCreateGame($roomId: ID!) {
    canaryCreateGame(roomId: $roomId) {
      gameId
    }
  }
`;