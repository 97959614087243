import React from "react"

export default function LayoutWrapper(props){
  const {children, code} = props;
  return (
    <div className="app">
      {code && <div className="banner">Code: {code}</div>}
      <div className="banner-offset">
        {children}
      </div>
    </div>
  );
}