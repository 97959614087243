
export default function reducer(state, action) {
  const { type, payload } = action;
  return reducerFunctions[type](state, payload);
}

function updateState(state, payload){
  return {
    ...state,
    ...payload,
  } 
}

const reducerFunctions = {
  updateState,
}