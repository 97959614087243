import * as firebase from "firebase";
const projectId = "yallbetcha-9627f";
var firebaseConfig = {
  apiKey: "AIzaSyBu2CW4f9aL7hsBDWfVm1d5U57uT2g5X6k",
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: "540553682078",
  appId: "1:540553682078:web:8ee3ff48071a0e00dcef8f",
};

console.log(
  "process.env.REACT_APP_EMULATE_BACKEND",
  process.env.REACT_APP_EMULATE_BACKEND
);
// this setup is local testing of functions / databases
if (process.env.REACT_APP_EMULATE_BACKEND) {
  firebaseConfig.databaseURL = `http://localhost:9000?ns=${projectId}`;
}
console.log(firebaseConfig);
firebase.initializeApp(firebaseConfig);

export const databaseRef = firebase.database().ref();
