import { useState, useEffect } from "react";
import { randomNumber } from "common/utils";


export default function useFlicker(defaultValue, flickValue, likelyhood, rate){
  const [style, setStyle] = useState({});

  useEffect(()=>{
    const interval = setInterval(() => {
      const num = randomNumber(likelyhood);
      const style = num == 1 ? flickValue : defaultValue
      setStyle(style)
    }, rate);
    return () => clearInterval(interval);
  },[setStyle])

  return style
}