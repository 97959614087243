
import { useReducer } from "react";

import { useRefFromState } from "common/hooks/state";


export default function useContextController(initialState, reducerFunctions, hookFunctions){

  
  const {
    state,
    dispatch,
    stateRef,
  } = useContextReducer(initialState, reducerFunctions);

  // all methods get access the current state and dispatch
  const methods = useContextMethods({stateRef, dispatch}, hookFunctions);

  // state and methods are made available to UI components
  const value = {
    ...state,
    ...methods,
    dispatch,
    state,
    stateRef,
  }
  return value
}


export function useContextReducer(initialState, reducerFunctions){
  function reducer(state, action) {
    const { type, payload } = action;
    return reducerFunctions[type](state, payload);
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRefFromState(state);

  return {state, dispatch, stateRef}
}


export function useContextMethods(params, methodHooks) {
  const hookParams = {
    methods: {},
    ...params,
  };

  // Here we're looping over our method hooks so
  // so each methods hook can utilize the methods defined before it
  methodHooks.forEach(([methodKey, methodHook]) => {
    hookParams.methods[methodKey] = methodHook(hookParams);
  });

  return hookParams.methods;
}
