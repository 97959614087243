import {gql, useMutation} from "@apollo/client"


export default function useCreateResponse(params){
  const {stateRef} = params;
  
  const [mutate] = useMutation(CREATE_RESPONSE)

  function createPrompt(text){
    const {
      viewProps,
      player,
    } = stateRef.current;

    const variables = {
      text,
      promptId:viewProps.prompt.id,
      playerId:player.id,
    };
    
    mutate({variables});
  }

  return createPrompt
}

const CREATE_RESPONSE = gql`
  mutation canaryCreateResponse(
    $promptId: ID!
    $playerId: ID!
    $text: String!
  ) {
    canaryCreateResponse(
      promptId: $promptId
      playerId: $playerId
      text: $text
    ) {
      responseId
    }
  }
`