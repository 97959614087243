import {useCallback} from "react"
import { gql, useMutation} from '@apollo/client';

export default function useResolveIntro(params){
  const {stateRef, checkForErrors} = params;
  
  const onCompleted = useCallback(
    function onCompleted({canaryResolveIntro:res}){
      if (checkForErrors(res)) return;
    },
    [checkForErrors]
  )

  const [mutate] = useMutation(GQL, {onCompleted});

  return useCallback(
    function resolveIntro(){
      const {gameId, isHost} = stateRef.current;
      if (!isHost) return;
      const variables = {
        gameId,
      }
      return mutate({variables})
    },
    [mutate, stateRef]
  )
}

const GQL = gql`
  mutation canaryResolveIntro($gameId: ID!) {
    canaryResolveIntro(gameId: $gameId) {
      gameId
    }
  }
`;