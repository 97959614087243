import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { apollo } from "common/apollo";
import { URLS } from "common/internal";
import Home from "home";
import PlayerView from "player/PlayerView"
import RoomView from "roomView/roomView"

import "./css/App.scss";

function App() {
  return (
    <ApolloProvider client={apollo}>
      <BrowserRouter>
        <Switch>
          <Route path={URLS.HOME} exact component={Home} />
          <Route path={URLS.ROOM} component={RoomView} />
          <Route path={URLS.PLAYER} component={PlayerView} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
