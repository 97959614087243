import { STORAGE_KEYS, SUB_VIEWS } from "../constants";

export default function updateRoomState(state, payload){
    const {room, saveCode} = payload;

    const isHost = state.roomClientId == room.hostScreenId;
    const subView = SUB_VIEWS.AUTHED;
    const playerSet = room.activePlayerSet;

    if (saveCode){
      localStorage.setItem(STORAGE_KEYS.ROOM_CODE, room.code);
    }

    return {
        ...state,
        isHost,
        room,
        subView,
        playerSet,
    }
}