export function INIT(){
  return {
    view: SUB_VIEWS.WAIT,
    errors:[],
  };
}

export const SUB_VIEWS = {
  WAIT: "wait",
  PROMPT: "prompt",
  INTRO: "intro",
  RESPONSE: "response",
  RESPONSE_VOTE: "response_vote",
  PROMPT_VOTE: "prompt_vote",
  SCORE: "score",
  WINNER: "winner",
};
