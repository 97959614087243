import {useEffect} from "react"
import { gsap } from "gsap";


import { VIEW_STATES } from "../context/constants";
import useViewContext from "../context/context";

const SPEED = 0.5;
const stagger = 0.25;
const opacity = 0;

export function useResultsAnimation(){
  const {viewProps, resolvePromptVote} = useViewContext();
  const {state} = viewProps

  useEffect(()=>{
    if (state !== VIEW_STATES.RESULTS) return;

    gsap.timeline({ paused: true })
      .from(".voter", SPEED, {
        x: -100,
        y: 100,
        opacity,
        stagger,
      })
      .from(
        ".author",
        SPEED,
        {
          x: -100,
          y: 100,
          opacity,
          stagger,
        },
        `-=${stagger}`
      )
      .addPause("+=6", resolvePromptVote)
      .play();
  },[state, resolvePromptVote])
}