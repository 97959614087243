import useCreatePrompt from "./useCreatePrompt";
import useCreateResponse from "./useCreateResponse";
import useCreateResponseVote from "./useCreateResponseVote";
import useCreatePromptVote from "./useCreatePromptVote";


export default function useViewAPI(stateRef, dispatch){
  const params = {
    stateRef,
    dispatch,
  };

  return {
    createPrompt: useCreatePrompt(params),
    createResponse: useCreateResponse(params),
    createResponseVote: useCreateResponseVote(params),
    createPromptVote: useCreatePromptVote(params),
  };
}
