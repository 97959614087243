import React, { useRef } from "react";
import { TextField, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { URLS } from "common/constants";

import useViewContext from "../context/context";
import { STORAGE_KEYS } from "../context/constants";

export default function LoginView(props){
  const {createPlayer, storedRoomCode, storedPlayerName} = useViewContext();
  const linkCode = null;
  const error = null;

  const nameRef = useRef();
  const codeRef = useRef();

  const urlPlayerName = useGetQueryParam("name")

  const defaultName = urlPlayerName || storedPlayerName

  function join(){
    const name = nameRef.current.value;
    const code = codeRef.current.value;
    createPlayer(code, name);
  }

  return (
    <div className="view-container width-limit">
      <h1>Join a Game Room!</h1>
      {!linkCode && (
        <div>
          <TextField
            className="input login-input"
            placeholder="Game Code"
            inputRef={codeRef}
            defaultValue={storedRoomCode}
          />
        </div>
      )}
      <div>
        <TextField
          className="input login-input"
          placeholder="Your name"
          autoFocus={true}
          inputRef={nameRef}
          defaultValue={defaultName}
        />
      </div>
      {error && <div className="form-error">{error}</div>}
      <div>
        <Button
          className="button"
          variant="contained"
          onClick={join}
        >
          GO
        </Button>
      </div>
    </div>
  )
}

export function RoomLink(props) {
  const {roomCode} = props.match.params;
  const history = useHistory();
  const {updateState} = useViewContext();
  localStorage.setItem(STORAGE_KEYS.ROOM_CODE, roomCode);
  updateState({storedRoomCode:roomCode});
  history.push(URLS.PLAYER);
  return null;
}

function useGetQueryParam(key){
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  return query.get(key)
}