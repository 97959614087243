import React, {useEffect, useState} from "react"
import gsap from "gsap";

import useViewContext from "../context/context";

export default function RoundTracker(){
  const {
    roundNumber: currentRound,
  } = useViewContext()
  
  const rounds = [1, 2, 3];

  const [isUp, setIsUp] = useState(false); 

  useEffect(()=>{
    if (!currentRound) return;
    if (isUp) return;
    setIsUp(true);
    const animation = gsap.timeline({ paused: true });
    animation.from(".round-circle", 0.5, { bottom: -100, stagger: 0.25 });
    animation.play();
  }, [currentRound, setIsUp, isUp])

  if (!currentRound) return null;

  return (
    <div className="round-tracker-container">
      {rounds.map((round) => (
        <Round 
          key={round}
          round={round}
          currentRound={currentRound}
        />
      ))}
    </div>
  );
}

function Round(props){
  const {
    round,
    currentRound,
  } = props;

  const stateClass = round == currentRound ? "circle-active" : round < currentRound ? "circle-past" : "";
  
  return (
    <div
      className={`round-circle circle-${round} ${stateClass}`}
    >
      <div className="round-tracker-title">Round</div>
      <div className="round-tracker-number">{round}</div>
    </div>
  )
}