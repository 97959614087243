import React, { useState, useRef, useEffect } from "react";

export function useRefFromState(state){
  const stateRef = useRef(state); 
  useEffect(()=>{
    stateRef.current = state;
  }, [state])
  return stateRef
}

export function useStateRef(initial_value){
    const [state, setState] = useState(initial_value);
    const stateRef = useRefFromState(state);
    return [state, setState, stateRef];
}
