import React from "react"

import { syncRandomItemWithSeed } from "common/utils";
import useRoomContext from "roomView/context/context"

import { DescriptionWrapper, Header } from "../layout"
import useViewContext from "../context/context";
import RemainingPlayers from "canary/RoomView/components/RemainingPlayers/RemainingPlayers";

export default function CreatePromptView(){
  const {playerSet} = useRoomContext()
  const {gameId} = useViewContext()
  const randomPlayer = syncRandomItemWithSeed(playerSet, gameId)
  
  return (
    <>
      <Header text="Write your prompts!" />
      <DescriptionWrapper>
        <p>
          Two other players will respond to your prompt, then everyone will
          vote for their favorite. SO SET THEM UP!
        </p>
        
        <p>
          <span className="inline-text">For example: </span>
          <span className="inline-text">
            "A great name for a dog shampoo..."
          </span>
          <span className="inline-text">
            or "The neighbor kids keep ______ing all my _______!"
          </span>
          <span className="inline-text">
            or "What keeps{" "}
            <span className="system-text">{randomPlayer.name}</span> up at
            night?"
          </span>
        </p>
      </DescriptionWrapper>
      <RemainingPlayers/>
    </>
  )
}