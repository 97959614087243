import React, {useEffect} from "react";
import { gsap } from "gsap";

import useViewContext from "../context/context";

const SPEED = 0.5;
const SPEED_INSTANT = 0.001;
const stagger = 0.5;
const opacity = 0;
const delay = 0.25;
const ease = "back";

export function usePromptAnimation(){
  useEffect(()=>{
    gsap.timeline({ paused: true })
      .from(".prompt-container", SPEED * 2, {
        x: 100,
        opacity,
        ease,
      })
      .from(
        ".response_0",
        SPEED * 2,
        {
          x: -100,
          opacity,
          ease,
        },
        "+=0.75"
      )
      .from(
        ".response_1",
        SPEED * 2,
        {
          x: 100,
          opacity,
          ease,
        },
        "+=0.25"
      )
      .from(
        ".remaining-players-container",
        SPEED * 2,
        {
          y:200,
          opacity,
          ease,
        }
      )
      .play();
  }, [])
}

export function useVotesAnimation(){
  const {viewProps, resolveResponseVote} = useViewContext();
  const {winner_id, is_tie_breaker, is_shut_out} = viewProps;
  
  useEffect(()=>{
    if (!winner_id) return;
    const animation = gsap.timeline({ paused: true });
    animation
      .from(
        ".voter_0",
        SPEED,
        {
          x: -100,
          y: 100,
          opacity,
          stagger,
        },
        1
      )
      .from(
        ".voter_1",
        SPEED,
        {
          x: 100,
          y: 100,
          opacity,
          stagger,
          delay,
        },
        1
      );

    if (is_tie_breaker) {
      animation
        .from(".tie", SPEED_INSTANT, { opacity }, "+=1")
        .from(".screen-2", SPEED_INSTANT, { opacity }, "+=3")
        .to(".tie", SPEED_INSTANT, { opacity }, "+=3")
        .from(
          ".author_bonus",
          SPEED / 2,
          {
            x: -100,
            y: 100,
            opacity,
            stagger,
          },
          "+=0.5"
        );
    }

    animation.from(".author", SPEED / 2, { opacity, y: 10, zoom: 4 });

    animation.to(".winner", SPEED / 2, {
      y: -10,
      repeat: -1,
      yoyo: true,
    });

    if (is_shut_out) {
      animation
        .from(".shutout", SPEED_INSTANT, { opacity }, "+=1")
        .to(".shutout", SPEED_INSTANT, { opacity }, "+=4")
        .from(
          ".shut_out_bonus",
          SPEED / 2,
          {
            x: -100,
            y: 100,
            opacity,
            stagger,
          },
          "+=0.5"
        );
    }
    animation.addPause("+=3", resolveResponseVote)
    animation.play();
  }, [winner_id, is_tie_breaker, is_shut_out, resolveResponseVote])
}

