import React from "react"
import { Button, TextareaAutosize } from "@material-ui/core";

export function Header({text}){
  return (
    <h2>{text.toUpperCase()}</h2>
  )
}

export function SubmitButton({onClick, text}){
  return (
    <Button
      className="button"
      onClick={onClick}
    >
      {text}
    </Button>
    )
}

export function UserText(props){
  const {text, children} = props;
  const content = text || children
  return <h1 className="user-text">{content}</h1>
}

export function TextInput({inputRef, ...inputProps}){
  return (
    <TextareaAutosize
      className="user-input"
      ref={inputRef}
      rows={1}
      autoFocus={true}
      {...inputProps}
    />
  );
}

export function VoteOption(props){
  const {
    text,
    id,
    onSelect,
  } = props;

  function handleClick(){
    onSelect(id)
  }
  return (
    <div
      className="vote-button"
      onClick={handleClick}
    >
      {text}
    </div>
  )
}