import { gql, useSubscription, useQuery} from '@apollo/client';

import useViewContext from '../context';


export default function useGameSubscription(){
  const {gameId, updateState} = useViewContext()

  function update(responseData){
    const {view, viewKey, props, roundNumber} = responseData;
    console.log("canary room game update", responseData);
    const viewProps = JSON.parse(props);
    updateState({
      view,
      viewKey,
      viewProps,
      roundNumber,
      errors:[],
    });
  }

  function onSubscriptionData({subscriptionData}){
    update(subscriptionData?.data?.canaryGameState)
  }

  function onCompleted(data){
    update(data?.canaryGameState)
  }

  const variables = {
    gameId,
  };

  useSubscription(SUB_GQL, {
    variables,
    onSubscriptionData
  })

  useQuery(QUERY_GQL, {
    variables,
    onCompleted,
  })
}

const SUB_GQL = gql`
  subscription onCanaryRoomStateChange(
    $gameId: ID!,
  ) {
    canaryGameState(
      gameId: $gameId,
    ) {
      view
      viewKey
      props
      roundNumber
    }
  }
`;

const QUERY_GQL = gql`
  query CanaryRoomState(
    $gameId: ID!,
  ) {
    canaryGameState(
      gameId: $gameId,
    ) {
      view
      viewKey
      props
      roundNumber
    }
  }
`;