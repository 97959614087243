import React from "react"

import { Switch, Route } from "react-router-dom";
import { URLS } from "common/constants";

import usePlayerContext, { ViewProvider } from "./context/context";
import { SUB_VIEWS } from "./context/constants";
import LoginView, {RoomLink} from "./LoginView/LoginView";
import PlayerRoomView from "./PlayerRoomView/PlayerRoomView";
import LayoutWrapper from "./layout";

export default function WrappedView(props) {
    return (
      <ViewProvider>
        <Router />
      </ViewProvider>
    );
}

function Router(){
  return (
    <Switch>
      <Route
        path={`${URLS.PLAYER}/:roomCode`}
        exact
        component={RoomLink}
      />
      <Route
        path={URLS.PLAYER}
        exact
        component={View}
      />
    </Switch>
  )
}

function View() {
  const {room, subView} = usePlayerContext();
  const SubView = SUB_VIEW_COMPONENTS[subView];

  return (
    <LayoutWrapper code={room?.code}>
      <SubView />
      <Errors />
    </LayoutWrapper>
  );
}

const SUB_VIEW_COMPONENTS = {
  [SUB_VIEWS.LOGIN]: LoginView,
  [SUB_VIEWS.ROOM]: PlayerRoomView,
};


function Errors() {
  const { errors } = usePlayerContext();
  if (!errors.length) return null;
  return (
    <div>
      {errors.map(error=>(
        <div>Error: {error}</div>
      ))}
    </div>
  )
}