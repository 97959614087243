import React, {useState, useEffect} from "react"
import { gsap } from "gsap";
import classNames from "classnames";

import useScoreContext, {ScoreProvider} from "./useScore"
import { Header } from "../layout"
import useViewContext from "../context";

export default function ScoreView(){
  return (
    <ScoreProvider>
      <Header text="Look who's winning!" />
      <ScoreContainer/>
    </ScoreProvider>
  )
}

function useAnimation(){
  const {dispatch, SCORE_KEYS} = useScoreContext();
  const {resolveScore} = useViewContext()

  function updateSort(){
    dispatch({
      type:"updateSortKey",
      payload: {
        sortKey:SCORE_KEYS.NEW,
      }
    })
  }

  useEffect(()=>{
    const animation = gsap.timeline({ paused: true });
    animation
      .call(
        updateSort,
        [],
        "+=1"
      )
      .call(resolveScore, [], "+=6")
      .play();
  },[])
}

function ScoreContainer(){
  const  {scores} = useScoreContext();
  useAnimation();

  const hasMany = scores.length > 4;

  return (
    <div
      className={classNames(
        "player-score-container",
        {
          ["has-many"]: hasMany,
        }
      )}
    >
      {scores.map((score) => (
        <Score
          key={score.player.name}
          score={score}
        />
      ))}
    </div>
  )
}

function Score(props){
  const {score} = props;
  const {
    sortedPlayerNames,
    distinctScores,
    isOldScores,
    scoreWidth,
    sortKey,
    SCORE_KEYS,
  } =  useScoreContext()

  const playerPosition = sortedPlayerNames.indexOf(
    score.player.name
  );

  const left = playerPosition * scoreWidth + "%";
  
  const scorePosition = distinctScores.indexOf(score[sortKey]);

  let percent = ((scorePosition + 1) / distinctScores.length) * 75;

  if (distinctScores.length === 1 && isOldScores) {
    percent = 0;
  }

  const containerStyle = { width: scoreWidth + "%", left }
  const barStyle = { height: percent + `%` }


  const oldScore = score[SCORE_KEYS.OLD]
  const newScore = score[SCORE_KEYS.NEW]


  return (
    <div
      className="player-score"
      style={containerStyle}
    >
      <div className="player-score-header">
        <div className="player-name">{score.player.name}</div>
      </div>

      <div className="score" style={barStyle}>
        <CountUp
          start={oldScore}
          end={newScore}
          duration={100}
          wait={false}
        />
      </div>

    </div>
  )
}


function CountUp(props){
  const { start, end, duration, wait} = props;

  const [currentNum, setCurrentNum] = useState(start)

  function tick(currentNum){
    if (currentNum >= end) return;
    const increment = (end - start) / duration;
    let updatedNum = currentNum + increment;
    if (updatedNum > end) updatedNum = end;
    setCurrentNum(updatedNum);
  }

  useEffect(()=>{
    if (wait) return;
    setTimeout(() => {
      tick(currentNum);
    }, 10);
  }, [currentNum, tick, wait])

  return parseInt(currentNum)
}