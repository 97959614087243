import React, { useEffect } from "react";
import { gsap } from "gsap";

import useViewContext from "canary/RoomView/context";
import { SCORE_KEYS } from "canary/RoomView/context/constants";
import { syncRandomItemWithSeed } from "common/utils";

import { clapGifs } from "canary/RoomView/context/gifs";

export default function WinnerView(){
  useAnimation()
  const {viewProps, gameId} = useViewContext()
  const {score_set} = viewProps;

  score_set.sort((a, b) => {
    return a[SCORE_KEYS.NEW] > b[SCORE_KEYS.NEW] ? -1 : 1;
  });
  const winner = score_set[0];
  const gif = syncRandomItemWithSeed(clapGifs, gameId);

  return (
    <>
      <div className="winner-view">
        <div
          className="full-screen-image"
          style={{ backgroundImage: `url(${gif})` }}
        >
          <div className="big-center-text">
            <div className="smaller-text">THE WINNER IS</div>
            <div className="player">{winner.player.name}</div>
            <div className="score smaller-text">
              {winner[SCORE_KEYS.NEW]} points!
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function useAnimation(){
  const {resolveGame} = useViewContext()

  useEffect(()=>{
    const SPEED = 0.5;
    const opacity = 0;
    const animation = gsap.timeline({ paused: true });
    animation
      .from(".player", SPEED, {
        x: -100,
        zoom: 4,
        opacity,
        delay: 0.5,
      })
      .from(".score", SPEED, {
        opacity,
      })
      .from(".gifContainer", SPEED, {
        opacity,
        x: 100,
        zoom: 4,
      })
      .addPause("+=6", resolveGame)
      .play();
  },[])
}