import React from "react"
import { Button as MButton,  TextField } from "@material-ui/core";

export default function RoomLayout(props) {
  const {code, children} = props;
  return (
    <div className="app">
      {code && (
        <div className="banner">
          Code <b>{code}</b>
        </div>
      )}
      <div className="banner-offset">
        <div className="view-container width-limit">
          {children}
        </div>
      </div>
    </div>
  );
}

export function Button(props){
  const {onClick, text, children} = props;

  const content = text || children;

  return (
    <MButton
      className="button"
      variant="contained"
      size="large"
      onClick={onClick}
    >
      {content}
    </MButton>
  )
}

export function Header(props){
  const {text, children} = props;
  const context = text || children
  return <h1>{context}</h1>
}

export function SubText(props){
  const {children} = props;
  return (
    <>
      {children}
    </>
  )
}


export function TextInput({inputRef, ...inputProps}){
  return (
    <TextField
      className="input login-input"
      inputRef={inputRef}
      autoFocus={true}
      {...inputProps}
    />
  );
}