import React from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// http://reactcommunity.org/react-transition-group/css-transition

import useViewContext from "canary/RoomView/context/context";
import "./RemainingPlayers.scss"

export default function RemainingPlayers(){
  const {viewProps} = useViewContext();

  const remainingPlayers = viewProps?.remaining_player_set;

  if (!remainingPlayers) return null;
  return (
    <TransitionGroup
      className="remaining-players-container option-container"
      >
      {remainingPlayers.map((player) => (
        <CSSTransition
          key={player.id}
          classNames="remaining-player"
          timeout={400}
        >
          <Player
            player={player}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

function Player({player}){
  return (
    <div className="remaining-player option">
      🤔 {player.name}
    </div>
  )
}