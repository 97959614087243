export default function updateSortKey(state, payload){
  const {scores, SCORE_KEYS} = state;
  const {sortKey} = payload;

  const sortedScores = [...scores]
    
  sortedScores.sort((a, b) => {
    return a[sortKey] > b[sortKey] ? -1 : 1;
  });

  const sortedPlayerNames = sortedScores.map(
    (score) => score.player.name
  );
  
  // get a list of all the score values without duplicates
  const distinctScores = [];
  scores.forEach((score) => {
    const scoreInt = score[sortKey];
    if (distinctScores.indexOf(scoreInt) === -1) {
      distinctScores.push(scoreInt);
    }
  });

  distinctScores.sort((a, b) => {
    return a < b ? -1 : 1;
  });

  const playerCount = scores.length;
  const scoreWidth = 100 / playerCount;

  const isOldScores = sortKey == SCORE_KEYS.OLD

  return {
    ...state,
    sortKey,
    sortedPlayerNames,
    distinctScores,
    scoreWidth,
    isOldScores,
    playerCount,
  }
}
  