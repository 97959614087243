export function INIT(){
  return {
    player:null,
    room:null,
    errors:[],
    subView:SUB_VIEWS.LOGIN,
    storedRoomCode: localStorage.getItem(STORAGE_KEYS.ROOM_CODE),
    storedPlayerName: localStorage.getItem(STORAGE_KEYS.PLAYER_NAME),
  };
}

export const SUB_VIEWS = {
  LOGIN: "login",
  ROOM: "room",
}

export const STORAGE_KEYS = {
  ROOM_CODE: "room_code",
  PLAYER_NAME: "player_name",
}