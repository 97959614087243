import React from "react"

import { syncRandomItem } from "common/utils";


export default function WaitView(){
  const text = syncRandomItem(LOOK_UP_OPTIONS)

  return (
    <>
      <h1>{text}</h1>
      <p>
        *Don't see a game screen? Go back to yallbetcha.com and read the
        instructions.
      </p>
    </>
  );
}

const LOOK_UP_OPTIONS =  [
  "Look up, dummy.",
  "Look up, friendo.",
  "Look up, creep.",
  "Look up, nerd.",
  "Look up, buddy.",
  "Look up, duder.",
  "Look up, dimwit.",
  "Stop looking at me! Look up!",
  "Nothing to see here. Look up!",
  "Dont worry about me. Look up!",
  "Mirror mirror, on the wall, stop looking at me...",
];