export const URLS = {
  HOME: "/",
  ROOM: "/room",
  PLAYER: "/player",
};

export const STORAGE_KEYS = {
  NAME_KEY: "username",
  ROOM_CODE_KEY: "room_code",
};
