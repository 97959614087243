import React from "react"

import useViewContext from "../context/context";
import { Header, UserText, VoteOption} from "../Components/layout";


export default function ResponseVoteView(){
  const {viewProps, api} = useViewContext()
  const {prompt, response_set} = viewProps;

  function castVote(promptId){
    api.createResponseVote(promptId);
  }

  return (
    <>
      <Header text="Choose your favorite response"/>
      <UserText text={prompt.text}/>
      {response_set.map((response) => (
        <VoteOption
          key={response.id}
          id={response.id}
          text={response.text}
          onSelect={castVote}
        />
      ))}

    </>
  );
}
