import { gql, useMutation} from '@apollo/client';

import { PLAYER_BASE, ROOM_BASE } from './frags';

export default function useCreatePlayer(params){
  const {dispatch, checkForErrors} = params;

  function onCompleted({createPlayer:res}){
    if (checkForErrors(res)) return;

    dispatch({
      type:"loginPlayer",
      payload: res
    })
  }

  const [mutate] = useMutation(CREATE_PLAYER, {
    onCompleted,
  });

  function createPlayer(roomCode, name){
    const variables = {
      roomCode,
      name,
    }
    return mutate({variables})
  }

  return createPlayer
}


const CREATE_PLAYER = gql`
  mutation createPlayer(
    $roomCode: String!,
    $name: String!
  ) {
    createPlayer(
      roomCode: $roomCode,
      name: $name
    ) {
      success
      errorMessage
      room {
        ...RoomBase
      }
      player {
        ...PlayerBase
      }
    } 
  }
  ${ROOM_BASE}
  ${PLAYER_BASE}
`;