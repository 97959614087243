import React from "react"

export default function LayoutWrapper(props){
  const {children} = props;

  return (
    <div className="canary player-view">
      <div className="wipe-offset">
        <div className="view-container">
          {children}
        </div>
      </div>
      <div className="wipe"></div>
    </div>
  );
}