import React from "react";
import classNames from "classnames";

import { Header } from "../layout";
import RemainingPlayers from "canary/RoomView/components/RemainingPlayers"
import useViewContext from "../context/context";
import { VIEW_STATES } from "../context/constants";
import { useResultsAnimation } from "./animations";

export default function PromptVoteView(){
  useResultsAnimation()
  return (
    <>
      <Header text="Give your favorite prompt some love!"/>
      <Prompts/>
      <RemainingPlayers/>
    </>
  )
}

function Prompts(){
  const {viewProps} = useViewContext()

  const {prompt_set, state} = viewProps;

  const showResults = state == VIEW_STATES.RESULTS

  return (
    <div className="option-container">
      {prompt_set.map((prompt) => (
        <Prompt
          key={prompt.id}
          prompt={prompt}
          showResults={showResults}
        />
      ))}
    </div>
  )
}

function Prompt(props){
  const {prompt, showResults} = props;
  return (
    <div
      className="prompt"
    >
      <div
        className={classNames(
          "author",
          {
            ["hidden"]: !showResults,
          }
        )}
      >
        {prompt.player.name}
      </div>
      <div className="prompt-body">{prompt.text}</div>
      <PromptVotes votes={prompt.votes}/>
    </div>
  )
}

function PromptVotes(props){
  const {votes} = props;
  if (!votes) return null;
  return (
    <div className="voter-container">
      {votes.map((voter) => (
        <div 
        key={voter.id}
        className="voter"
        >
          {voter.name}
        </div>
      ))}
    </div>
  )

}