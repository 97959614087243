import React, {useRef} from "react";

import { Button, Header, TextInput } from "roomView/layout";
import useViewContext from "roomView/context/context";
import {SUB_VIEWS} from "roomView/context/constants";
import { SubText } from "../layout";

export function LoginChoiceView(){
  const {updateState, createRoom} = useViewContext();

  function create(){
    createRoom()
  }

  function join(){
    updateState({
      subView:SUB_VIEWS.LOGIN
    })
  }
  
  return (
    <>
      <Header text="Room Screen"/>
      <SubText>
        <p>Make sure all players can see this screen.</p>
      </SubText>
      <div>
        <Button
          onClick={create}
          text="Start a new Game Room"
        />
      </div>
      <div>
        <Button
          onClick={join}
          text="Join an existing Game Room"
        />
      </div>
    </>
  );
}

export default function LoginView(){
  const {getRoomByCode, storedRoomCode} = useViewContext(); 
  const inputRef = useRef();
  
  function login(){
    const code = inputRef.current.value
    getRoomByCode(code)
  }

  return (
    <>
      <Header>Join a Game Room!</Header>
      <div>
        <TextInput
          placeholder="Room Code"
          inputRef={inputRef}
          defaultValue={storedRoomCode}
        />
      </div>
      <div>
        <Button className="button" onClick={login}>
          GO
        </Button>
      </div>
    </>
  )
}