import {gql, useMutation} from "@apollo/client"


export default function useCreatePrompt(params){
  const {stateRef} = params;
  
  const [mutate] = useMutation(CREATE_PROMPT)

  function createPrompt(text){
    const {
      gameId,
      player,
    } = stateRef.current;
    
    const variables = {
      text,
      gameId,
      playerId:player.id,
    };
    
    mutate({variables});
  }

  return createPrompt
}

const CREATE_PROMPT = gql`
  mutation canaryCreatePrompt(
    $gameId: ID!
    $playerId: ID!
    $text: String!
  ) {
  canaryCreatePrompt(
    gameId: $gameId,
    playerId: $playerId,
    text: $text,
  ) {
    promptId
  }
}
`