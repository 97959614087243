import React, { createContext, useReducer, useContext, useEffect } from "react";

import { useRefFromState } from "common/hooks/state";

import reducer from "./reducer/reducer"
import useViewAPI from "./api/useViewAPI";
import { INIT } from "./constants";


export const RoomViewContext = createContext();

const { Provider } = RoomViewContext;

export function ViewProvider(props){
  const {children} = props;
  
  const [state, dispatch] = useReducer(reducer, {...INIT()});
  const stateRef = useRefFromState(state);

  function updateState(payload) {
    dispatch({
      type: "updateState",
      payload,
    });
  }

  function setErrors(errors){
    if (errors.length) console.debug(errors);
    updateState({errors})
  }

  const api = useViewAPI(stateRef, dispatch, setErrors);

  const value = {
    ...state,
    ...api,
    state,
    stateRef,
    dispatch,
    updateState,
    setErrors,
    api,
  }

  return (<Provider value={value}>{children}</Provider>);
}

export default function useViewContext(){
  return useContext(RoomViewContext);
}