import {useCallback} from "react"
import { gql, useMutation} from '@apollo/client';

import { ROOM_BASE } from "./frags";


export default function useCreateRoom(params){
  const {stateRef, dispatch, checkForErrors} = params;
  
  function onCompleted({createRoom:res}){
    if (checkForErrors(res)) return;
    const {room} = res
    dispatch({
      type:"updateRoomState",
      payload: {
        room,
        saveCode:true,
      }
    })
  }

  const [mutate] = useMutation(GQL, {
    onCompleted,
  });

  const createRoom = useCallback(
    function createRoom(){
      const {roomClientId} = stateRef.current;
      const variables = {
        screenId:roomClientId,
      }
      return mutate({variables})
    },
    [mutate]
  )

  return createRoom
}

const GQL = gql`
  mutation createRoom(
    $screenId: String!
  ) {
    createRoom(
      screenId: $screenId
    ) {
      room {
        ...RoomBase      
      }
    }
  }
  ${ROOM_BASE}
`;