import {gql, useMutation} from "@apollo/client"


export default function useCreateResponseVote(params){
  const {stateRef} = params;
  
  const [mutate] = useMutation(CREATE_RESPONSE_VOTE)

  function createResponseView(responseId){
    const {
      player,
    } = stateRef.current;
    
    const variables = {
      responseId,
      playerId:player.id,
    };
    
    mutate({variables});
  }

  return createResponseView
}

const CREATE_RESPONSE_VOTE = gql`
  mutation canaryCreateResponseVote(
    $responseId: ID!,
    $playerId: ID!
  ) {
    canaryCreateResponseVote(
      responseId: $responseId,
      playerId: $playerId
    ) {
      responseId
    }
  }
`