import React from "react";

import useViewContext from "../context/context";
import RemainingPlayers from "canary/RoomView/components/RemainingPlayers";

import { usePromptAnimation, useVotesAnimation} from "./animations";

import { Header } from "../layout";
import ShutOut from "./ShutOut"
import Tie from "./Tie";

export default function ResponseVoteView(){
  usePromptAnimation()
  useVotesAnimation()
  return (
    <>
      <Header text="Get out the Vote!"/>
      <Prompt/>
      <Responses/>
      <ShutOut/>
      <Tie/>
      <RemainingPlayers/>
    </>
  )
}

function Prompt(){
  const {viewProps} = useViewContext();
  const {prompt} = viewProps;
  return (
    <div className="prompt-container">
      <h1 className="user-text">
        {prompt.text}
      </h1>
    </div>
  )
}

function Responses(){
  const {viewProps} = useViewContext();
  const {response_set, winner_id, is_tie_breaker, is_shut_out} = viewProps;
  return (
    <div className="responses-container">
      {response_set.map((response, response_index) => (
        <div 
          key={response.id}
          className="response-container"
        >
          <Response
            key={response.id}
            idx={response_index}
            response={response}
            winnerId={winner_id}
          />
          <ResponseVotes
            votes={response.votes}
            idx={response_index}
            isWinner={response.id == winner_id}
            isTie={is_tie_breaker}
            isShutOut={is_shut_out}
          />
        </div>
      ))}
    </div>
  )
}

function Response(props){
  const {response, idx, winnerId} = props;
  const isWinner = response.id == winnerId;
  let classes = `response vote-button response_${idx}`
  let authorClasses = "author"
  if (isWinner) classes += " winner"
  else if (!winnerId) authorClasses += " hidden"

  return (
    <div
      className={classes}
    >
      <div
        className={authorClasses}
      >
        {response.player.name}
      </div>
      {response.text}
    </div>
  )
}

function ResponseVotes(props){
  const {votes, idx, isWinner, isShutOut, isTie} = props;
  if (!votes) return null;
  return (
    <div className="voter-container stack">
      {votes.map((voter)=>(
        <div
          key={voter.name}
          className={`voter_${idx} voter`}
        >
          ☝️ {voter.name}
        </div>
      ))}
      {isTie && isWinner && (
        <div className={`author_bonus bonus voter`}>
          ✍️ author bonus!
        </div>
      )}
      {isShutOut && isWinner && (
        <div className={`shut_out_bonus bonus voter`}>
          💪 Shut out bonus!
        </div>
      )}
    </div>
  )
}

