export function INIT(){
  return {
    errors:[],
    playerSet:[],
    subView:SUB_VIEWS.LOGIN_CHOICE,
    roomClientId: Date.now().valueOf(),
    storedRoomCode: localStorage.getItem(STORAGE_KEYS.ROOM_CODE),
  };
}

export const SUB_VIEWS = {
  LOGIN_CHOICE: "login-choice",
  LOGIN: "login",
  AUTHED: "authed",
}

export const STORAGE_KEYS = {
  ROOM_CODE: "room-code",
}