import React, {createContext, useContext, useMemo} from "react";

import useContextState from "common/hooks/useContextController";

import useViewContext from "canary/RoomView/context";
import { SCORE_KEYS } from "../../context/constants";

import updateSortKey from "./reducers/updateSortKey";

const ScoreContext = createContext();

const { Provider } = ScoreContext;


export default function useScoreContext(){
  return useContext(ScoreContext);
}

export function ScoreProvider({children}){
  const {viewProps} = useViewContext()
  const {score_set = []} = viewProps;
  
  const initialState = updateSortKey({ 
    scores:score_set,
    SCORE_KEYS,
  }, {
    sortKey:SCORE_KEYS.OLD,
  })

  const value = useContextState(initialState, reducerFunctions, hookFunctions)

  return (
    <Provider value={value}>{children}</Provider>
  )
}

const reducerFunctions = {
  updateSortKey,
}

const hookFunctions = [
]


