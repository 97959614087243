import {useCallback} from "react"
import { gql, useMutation} from '@apollo/client';


export default function useRoomPing(params){
  // TODO: Refactor this so it doesn't cause a rerender of the whole context
  const {stateRef} = params;
  
  const [mutate] = useMutation(GQL);

  const callback = useCallback(() => {
      const {room, roomClientId} = stateRef.current;
      const variables = {
        roomId:room.id,
        screenId:roomClientId,
      }
      return mutate({variables})
    },
    [mutate, stateRef]
  )

  return callback
}

const GQL = gql`
  mutation screenPing($roomId: ID!, $screenId: ID!) {
    screenPing(roomId: $roomId, screenId: $screenId) {
      hostScreenId
    }
  }
`;