import {useCheckForErrors} from "common/hooks/useHandlerErrors";

import useCreatePlayer from "./useCreatePlayer";
import useStartGame from "./useStartGame";

export default function useViewAPI(stateRef, dispatch, setErrors){
  const checkForErrors = useCheckForErrors(setErrors);

  const params = {
    stateRef,
    dispatch,
    setErrors,
    checkForErrors,
  };

  return {
    createPlayer: useCreatePlayer(params),
    startGame: useStartGame(params),
  };
}