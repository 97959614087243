import React from "react"

import useRoomContext from "roomView/context/context"

import useGameSubscription from "./context/api/useGameSubscription";
import useViewContext, { ViewProvider } from "./context/context";
import { SUB_VIEWS } from "./context/constants";
import IntroView from "./IntroView/IntroView";
import CreatePromptView from "./CreatePromptView";
import RoomLayoutWrapper from "./layout";
import ResponseView from "./ResponseView";
import ResponseVoteView from "./ResponseVoteView";
import PromptVoteView from "./PromptVoteView";
import ScoreView from "./ScoreView";
import WinnerView from "./WinnerView";

export default function CanaryRoomView(props) {
  const {room, isHost} = useRoomContext()
  return (
    <ViewProvider isHost={isHost} gameId={room.gameId}>
      <View />
    </ViewProvider>
  );
}

function View() {
  const {view, viewKey} = useViewContext()
  const SubView = SUB_VIEW_COMPONENTS[view] || FallbackView
  useGameSubscription()
  return (
    <RoomLayoutWrapper>
      <SubView key={viewKey}/>
      <Errors />
    </RoomLayoutWrapper>
  );
}

const SUB_VIEW_COMPONENTS = {
  [SUB_VIEWS.FALLBACK]: FallbackView,
  [SUB_VIEWS.INTRO]: IntroView,
  [SUB_VIEWS.PROMPT]: CreatePromptView,
  [SUB_VIEWS.RESPONSE]: ResponseView,
  [SUB_VIEWS.RESPONSE_VOTE]: ResponseVoteView,
  [SUB_VIEWS.PROMPT_VOTE]: PromptVoteView,
  [SUB_VIEWS.SCORE]: ScoreView,
  [SUB_VIEWS.WINNER]: WinnerView,
}

function FallbackView(){
  return null;
}

function Errors() {
  const { errors } = useViewContext();
  if (!errors.length) return null;

  return (
    <div>
      Errors:
      <ul>
        {errors.map(error=>(
          <li>{error}</li>
        ))}
      </ul>
    </div>
  )
}