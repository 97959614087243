import {gql, useMutation} from "@apollo/client"


export default function useCreatePromptVote(params){
  const {stateRef} = params;
  
  const [mutate] = useMutation(CREATE_PROMPT_VOTE)

  function createPromptView(promptId){
    const {
      player,
    } = stateRef.current;
    
    const variables = {
      promptId,
      playerId:player.id,
    };
    
    mutate({variables});
  }

  return createPromptView
}

const CREATE_PROMPT_VOTE = gql`
  mutation canaryCreatePromptVote(
    $promptId: ID!
    $playerId: ID!
  ) {
    canaryCreatePromptVote(
      promptId: $promptId,
      playerId: $playerId,
    ) {
      promptId
    }
  }
`