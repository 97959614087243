import React from "react"
import { Button } from "@material-ui/core";

import CanaryPlayerView from "canary/PlayerView/CanaryPlayerView"
import usePlayerContext from "player/context/context"
import useRoomSubscription from "player/context/api/useRoomSubscription";
import PlayerLinkButton from "common/components/PlayerLinkButton";

export default function PlayerRoomView(){
  const {room} = usePlayerContext()
  useRoomSubscription(room.id)
  if (room.gameId) return <CanaryPlayerView/>
  return (
    <LobbyView/>
  )
}

function LobbyView(){
  const {api, player, room} = usePlayerContext();
  const canStart = room.activePlayerSet.length > 2;
  const isHost = room.hostPlayer.id === player.id;
  
  function startGame(){
    api.startGame();
  }

  return (
    <div className="view-container width-limit">
      <div>Welcome {player.name}!</div>
      {!canStart ? (
        <div>
          It takes 3 to tango, partner. Go get more folks to join, why dontcha?
        </div>
      ) : isHost ? (
        <div>
          Everyone here? 
          <Button className="button" onClick={startGame}>
            Start the game!
          </Button>
        </div>
      ) : (
        <div>
          Everyone here? Tell {room.hostPlayer.name} to push the button
          already!
        </div>
      )}
      <PlayerLinkButton code={room.code}/>
    </div>
  );
}