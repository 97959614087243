export function randomNumber(max=100) {
  return Math.floor(Math.random() * max);
}

export function randomItem(myArray) {
  return myArray[randomNumber(myArray.length)];
}

export function objectToArray(object) {
  let array = [];
  if (object) {
    array = Object.keys(object).map((key) => {
      const item = object[key];
      item["key"] = key;
      return object[key];
    });
  }
  return array;
}

/**
 * Pick a seemly random item from an array based on the time
 * so synchronized views will choose the same item
 * @param {*} optionsArray
 */
export function syncRandomItem(optionsArray) {
  let seedValue = Date.now();
  seedValue = seedValue.toString();
  // reduce the time to tens of second to minimize out of sync browsers
  seedValue = seedValue.slice(0, -4);
  seedValue = parseInt(seedValue);
  return syncRandomItemWithSeed(optionsArray, seedValue)
}

export function syncRandomItemWithSeed(optionsArray, seedNumber) {
  return optionsArray[seedNumber % optionsArray.length];
}
