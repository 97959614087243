export function INIT(overrides = {}){
  return {
    errors:[],
    view: SUB_VIEWS.FALLBACK,
    viewKey:null,
    viewProps:null,
    roundNumber:0,
    ...overrides,
  };
}

export const SUB_VIEWS = {
  FALLBACK: "fallback",
  INTRO: "intro",
  PROMPT: "prompt",
  RESPONSE: "response",
  RESPONSE_VOTE: "response_vote",
  PROMPT_VOTE: "prompt_vote",
  SCORE: "score",
  WINNER: "winner",
};

export const VIEW_STATES = {
  RESULTS: "results",
  VOTE: "vote",
};

export const SCORE_KEYS = {
  OLD: "old_score",
  NEW: "new_score",
};