import { useCallback } from "react"
import { gql, useMutation } from '@apollo/client';

import { ROOM_BASE } from "./frags";

export default function useGetRoomByCode(params){
  const {stateRef, dispatch, checkForErrors} = params;
  
  const onCompleted = useCallback(
    function onCompleted({createRoom:res}){
      if (checkForErrors(res)) return;
      const {room} = res
      dispatch({
        type:"updateRoomState",
        payload: {
          room,
          saveCode:true,
        }
      })
    },
    [checkForErrors]
  )

  const [mutate] = useMutation(GQL, {
    onCompleted,
    fetchPolicy: "no-cache",
  });

  return useCallback(
    function getRoomByCode(code){
      const {roomClientId} = stateRef.current;
      const variables = {
        code,
        screenId:roomClientId,
      }
      return mutate({variables})
    },
    [mutate, stateRef]
  )
}


const GQL = gql`
  mutation createRoom(
    $screenId: String!
    $code: String!
  ) {
    createRoom(
      screenId: $screenId
      code: $code
    ) {
      success
      errorMessage
      room {
        ...RoomBase    
      }
    }
  }
  ${ROOM_BASE}
`;