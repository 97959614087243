import React from "react";

import RemainingPlayers from "canary/RoomView/components/RemainingPlayers/RemainingPlayers";
import { DescriptionWrapper, Header } from "../layout"

export default function ResponseView(){
  return (
    <>
      <Header text="Write your responses!" />
      <DescriptionWrapper>
        <p>
          On your device, you will write a response to two other player's prompts.
          Two players will respond to each prompt, then everyone else will vote for their favorite.
          SO BE FUNNY!
        </p>
      </DescriptionWrapper>
      <RemainingPlayers/>
    </>
  )
}