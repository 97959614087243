import React, { createContext, useReducer, useContext, useCallback } from "react";

import { useRefFromState } from "common/hooks/state";

import useRoom from "roomView/context/context";

import reducer from "./reducer/reducer"
import useViewAPI from "./api/useViewAPI";
import { INIT } from "./constants";


export function ViewProvider(props){
  const {children} = props;

  const {room, isHost} = useRoom()

  const initialState = INIT({
    gameId:room.gameId,
    isHost,
  })
  
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRefFromState(state);

  const updateState = useCallback(
    function updateState(payload) {
      dispatch({
        type: "updateState",
        payload,
      });
    }, [dispatch]
  )
  
  const setErrors = useCallback(
    function setErrors(errors){
      if (errors.length) console.debug(errors);
      updateState({errors})
    }, [updateState]
  )

  const api = useViewAPI(stateRef, dispatch, setErrors);

  window.gameState = state

  const value = {
    ...state,
    ...api,
    state,
    stateRef,
    dispatch,
    updateState,
    setErrors,
    api,
  }

  return (<Provider value={value}>{children}</Provider>);
}

export default function useViewContext(){
  return useContext(CanaryRoomViewContext);
}

export const CanaryRoomViewContext = createContext();

const { Provider } = CanaryRoomViewContext;