import React, {useState, useEffect} from "react";

import PlayerLinkButton from "common/components/PlayerLinkButton";
import CanaryRoomView from "canary/RoomView/CanaryRoomView";

import useViewContext from "roomView/context/context";
import { Header, SubText, Button } from "roomView/layout";
import useRoomSubscription from "roomView/context/api/useRoomSubscription";


export default function AuthedView(){
  const {room} = useViewContext()
  useRoomSubscription()
  useKeepAlive()
  if (room.gameId) return <CanaryRoomView/>
  return (
    <LobbyView/>
  )
}

function LobbyView(){
  const {room, isHost, removePlayer} = useViewContext()
  const players = room.activePlayerSet;

  const [editMode, setEditMode] = useState(false);
  
  function toggleEdit(){
    if (!isHost) return;
    setEditMode(current => !current);
  }

  return (
    <>
      <Header>Players</Header>
      
      {players.length < 1 && (
        <SubText>
          <p>Where is everyone?</p>
        </SubText>
      )}
      
      {players.map((player, index) => (
        <LobbyPlayer
          key={player.id}
          {...{player, index, editMode, removePlayer}}
        />
      ))}

      <PlayerLinkButton code={room.code} />
      {isHost && (
        <div>
          <Button onClick={toggleEdit}>
            {editMode ?  "Done Editing Players": "Remove Players"}
          </Button>
        </div>
      )}
    </>
  );
}

/**
 * Lets the server know this room client is still active
 */
function useKeepAlive(){
  const {roomPing} = useViewContext()
  useEffect(() => {
    const interval = setInterval(() => {
      roomPing()
    }, 2000);
    return () => clearInterval(interval);
  }, []);
}

function LobbyPlayer(props){
  const {
    player,
    index,
    editMode,
    removePlayer,
  } = props

  function remove(){
    removePlayer(player.id)
  }

  return (
    <div>
      {index + 1}. {player.name} {editMode && <span className="pointer" onClick={remove}>(👢)</span>}
    </div>
  ); 
}