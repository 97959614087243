import {useCheckForErrors} from "common/hooks/useHandlerErrors";

import useCreateRoom from "./useCreateRoom";
import useRemovePlayer from "./useRemovePlayer";
import useRoomPing from "./useRoomPing";
import useGetRoomByCode from "./useGetRoomByCode";

export default function useViewAPI(stateRef, dispatch, setErrors){
  const checkForErrors = useCheckForErrors(setErrors);

  const props = {
    stateRef,
    dispatch,
    setErrors,
    checkForErrors,
  };

  return {
    createRoom: useCreateRoom(props),
    removePlayer: useRemovePlayer(props),
    roomPing: useRoomPing(props),
    getRoomByCode: useGetRoomByCode(props),
  };
}
