import {useCheckForErrors} from "common/hooks/useHandlerErrors";

import useResolveIntro from "./useResolveIntro";
import useResolveResponseVote from "./useResolveResponseVote";
import useResolvePromptVote from "./useResolvePromptVote";
import useResolveScore from "./useResolveScore";
import useResolveGame from "./useResolveGame";


export default function useViewAPI(stateRef, dispatch, setErrors){
  const checkForErrors = useCheckForErrors(setErrors);

  const props = {
    stateRef,
    dispatch,
    setErrors,
    checkForErrors,
  };

  return {
    resolveIntro: useResolveIntro(props),
    resolveResponseVote: useResolveResponseVote(props),
    resolvePromptVote: useResolvePromptVote(props),
    resolveScore: useResolveScore(props),
    resolveGame: useResolveGame(props),
  };
}