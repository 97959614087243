import { gql } from '@apollo/client';


export const PLAYER_BASE = gql`
  fragment PlayerBase on Player {
    id
    name
  }
`;


export const ROOM_BASE = gql`
  fragment RoomBase on Room {
    id
    code
    hostScreenId
    hostPlayer {
      ...PlayerBase
    }
    activePlayerSet {
      ...PlayerBase
    }
    gameId
  }
  ${PLAYER_BASE}
`;
