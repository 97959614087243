import React, {useState} from "react";
import { Button } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { URLS } from "common/constants";


export default function PlayerLinkButton({code}){
  const [isCopied, setIsCopied] = useState(false);

  function handleCopy(){
    setIsCopied(true)
    setTimeout(()=>{
      setIsCopied(false)
    })
  }
  const domain = window.location.hostname;
  const port = window.location.port;
  const link = `${domain}${port ? ":" + port : ""}${URLS.PLAYER}/${code}`;

  return (
    <CopyToClipboard text={link} onCopy={handleCopy}>
      <Button>
        {isCopied ? "Copied Player Link" : "Copy Player Link"}
      </Button>
    </CopyToClipboard>
  );
}