import { gql, useSubscription} from '@apollo/client';
import useViewContext from '../context';
import { ROOM_BASE } from "./frags";


export default function useRoomSubscription(roomId){
  const {updateState} = useViewContext()

  function onSubscriptionData({subscriptionData}){
    const {room} = subscriptionData?.data?.roomStateChange
    console.log("useRoomSubscription", room)
    updateState({room});
  }
  
  useSubscription(ROOM_STATE_SUBSCRIPTION, {
    variables: {roomId},
    onSubscriptionData
  })
}

const ROOM_STATE_SUBSCRIPTION = gql`
  subscription onRoomStateChange($roomId: ID!) {
    roomStateChange(roomId: $roomId) {
      room {
        ...RoomBase
      }
    }
  }
  ${ROOM_BASE}
`;
