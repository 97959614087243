import { STORAGE_KEYS, SUB_VIEWS } from "../constants";

/**
 * Set the current player
 */
export default function loginPlayer(state, payload){
    const {room, player} = payload;

    localStorage.setItem(STORAGE_KEYS.ROOM_CODE, room.code);
    localStorage.setItem(STORAGE_KEYS.PLAYER_NAME, player.name);

    const subView = SUB_VIEWS.ROOM;
    
    return {
      ...state,
      room,
      player,
      subView,
    }
}