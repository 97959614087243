import React, {useRef} from "react"

import useViewContext from "../context/context";
import { Header, SubmitButton, TextInput, UserText} from "../Components/layout";


export default function ResponseView(){
  const {viewProps, setErrors, api} = useViewContext()
  const {prompt} = viewProps;

  const inputRef = useRef();

  function handleSubmit(){
    const userText = inputRef.current.value.trim();
    const error = validateResponse(userText)
    if (error){
      setErrors([error])
    } else {
      api.createResponse(userText);
    }
  }

  return (
    <>
      <Header text="Enter your response"/>
      <UserText text={prompt.text}/>
      <TextInput
        inputRef={inputRef}
        placeholder="Write something already..."
      />
      <SubmitButton text="Submit" onClick={handleSubmit}/>
    </>
  );
}

function validateResponse(text) {
  const length = text.length;
  if (!length) {
    return "Nothing? You wrote nothing? Try again.";
  } else if (length > 280) {
    return "Woah woah woah, Herman Melville. Cool it.";
  }
}