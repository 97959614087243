import { useState } from "react";

export default function useHandlerErrors() {
  const [errors, setErrors] = useState([]);
  const [errorData, setErrorData] = useState();

  const checkForErrors = useCheckForErrors(setErrors, setErrorData);

  return [errors, setErrors, checkForErrors, errorData];
}

export function useCheckForErrors(setErrors, setErrorData = () => {}) {
  function checkForErrors(res) {
    if (res?.errorMessage) {
      setErrors([res?.errorMessage]);
      setErrorData(parseErrorData(res));
      return true;
    }
    setErrors([]);
    setErrorData();
  }
  return checkForErrors;
}

function parseErrorData(res) {
  if (!res.errorData) return null;
  return JSON.parse(res.errorData);
}
