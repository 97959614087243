import { gql, useSubscription, useQuery} from '@apollo/client';

import useViewContext from '../context';


export default function usePlayerSubscription(roomId){
  const {gameId, player, updateState} = useViewContext()

  function update(responseData){
    const {view, viewKey, props, roundNumber} = responseData;
    const viewProps = JSON.parse(props);
    updateState({
      view,
      viewKey,
      viewProps,
      roundNumber,
      errors:[],
    });
  }

  function onSubscriptionData({subscriptionData}){
    update(subscriptionData?.data?.canaryPlayerState)
  }

  function onCompleted(data){
    update(data?.canaryPlayerState)
  }

  const variables = {
    gameId,
    playerId:player.id,
  };

  useSubscription(PLAYER_STATE_SUB, {
    variables,
    onSubscriptionData
  })


  useQuery(PLAYER_STATE_QUERY, {
    variables,
    onCompleted,
  })
}

const PLAYER_STATE_SUB = gql`
  subscription onCanaryPlayerStateChange(
    $gameId: ID!,
    $playerId: ID!,
  ) {
    canaryPlayerState(
      gameId: $gameId,
      playerId: $playerId
    ) {
      view
      viewKey
      props
      roundNumber
    }
  }
`;

const PLAYER_STATE_QUERY = gql`
  query CanaryPlayerState(
    $gameId: ID!,
    $playerId: ID!,
  ) {
    canaryPlayerState(
      gameId: $gameId,
      playerId: $playerId
    ) {
      view
      viewKey
      props
      roundNumber
    }
  }
`;