import React, {useRef, useState, useEffect} from "react"
import { Button } from "@material-ui/core";

import { randomItem } from "common/utils";
import useViewContext from "../context/context";
import { Header, SubmitButton, TextInput} from "canary/PlayerView/Components/layout";
import { UserText } from "../Components/layout";

export default function PromptView(){
  const {setErrors, createPrompt} = useViewContext()
  const viewProps = {
    setErrors,
    createPrompt,
  }
  return <View {...viewProps}/>
}

export function View(props){
  const {
    setErrors = (errors)=>console.log("errors:", errors),
    createPrompt = (prompt)=>console.log("prompt:", prompt),
  } = props;

  const inputRef = useRef();

  const [template, setTemplate] = useState();
  const [prompt, setPrompt] = useState();

  function handleSubmit(){
    const userText = inputRef.current.value.trim();
    const error = validatePrompt(userText)
    if (error){
      setErrors([error])
    } else {
      createPrompt(userText);
    }
  }

  function suggest(){
    let suggestion = randomItem(SUGGESTIONS);
    setTemplate(suggestion);
  }

  function applyTemplate(prompt){
    if (prompt) setPrompt(prompt);
    setTemplate()
  }

  return (
    <>
      <Header text="Write your Prompt"/>
      {template ? (
        <PromptTemplate
          template={template}
          applyTemplate={applyTemplate}
        />
      ) : (
        <>
          <TextInput
            inputRef={inputRef}
            placeholder="Write something already..."
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
          />
          <SubmitButton text="Submit" onClick={handleSubmit}/>
        </>
      )}
      <Button
        onClick={suggest}
        variant="outlined"
      >
        Suggest-a-prompt
      </Button>
    </>
  );
}

function validatePrompt(promptText) {
  const length = promptText.length;
  if (!length) {
    return "Nothing? You wrote nothing? Try again.";
  } else if (length > 280) {
    return "Woah woah woah, Herman Melville. Cool it.";
  }
}

function PromptTemplate(props){
  const {template, applyTemplate} = props;
  const {setErrors} = useViewContext()

  const [templateParts, setTemplateParts] = useState([])

  useEffect(()=>{
    let processTemplate = template;
    const parts = [];
    while(true){
      const startIdx =  processTemplate.indexOf("<")
      if (startIdx > -1) {
        const textChunk = processTemplate.substring(0, startIdx)
        const endIdx = processTemplate.indexOf(">")
        const inputChunk = processTemplate.substring(startIdx + 1, endIdx)
        processTemplate = processTemplate.substring(endIdx + 1)
        parts.push({text:textChunk})
        parts.push({input:true, placeholder:inputChunk})
      } else {
        parts.push({text:processTemplate})
        break
      }
    }
    setTemplateParts(parts)
  }, [template, setTemplateParts])

  function apply(){
    let prompt = ""
    let missingInput = false;
    templateParts.forEach(chunk => {
      if ( chunk.input && !chunk.text){
        missingInput = true;
        return
      }
      prompt += chunk.text;
    })
    if (missingInput) {
      setErrors(["Fill in all blanks"])
      return
    }
    applyTemplate(prompt)
  }

  function cancel(){
    applyTemplate("")
  }

  function makeInput(index){
    templateParts[index].input=true
    setTemplateParts([...templateParts])
  }

  function updateText(e, index){
    templateParts[index].text= e.target.value
    setTemplateParts([...templateParts])
  }

  return (
    <>
      <UserText>
        "
        {templateParts.map((chunk, index)=>(
          <span
          key={index}
          >
            {chunk.input ? (
              <input
                className="user-input-inline"
                type="text"
                value={chunk.text}
                placeholder={chunk.placeholder}
                onChange={e => updateText(e, index)}
              />
              ) : (
              <span
                onClick={e => makeInput(index)}
              >
                {chunk.text}
              </span>
              )}
          </span>
        ))}
        "
      </UserText>
      <Button className="button" onClick={apply}>Use It</Button>
      <Button className="mr-2" variant="outlined" onClick={cancel}>Write my own</Button>
    </>
  )
}

const SUGGESTIONS = [
  "<blank> would have been a whole lot <blankier> if…",
  "<Person> has a side gig. On Tuesday nights from 8-10 they...",
  "I don't <> anymore. Not after…",
  "I like my <> like I like my <>:",
  "I've never seen <person> more excited than when...",
  "If <> could talk they probably say…",
  "If <person> had their own life style brand, what would they sell?",
  "If <person> were a <category of thing> what kind would they be?",
  "Sure <> is good, but have you ever tried ______?",
  "The <blankest> three words you'll ever hear:",
  "The best part of <> is...",
  "The real reason <>",
  "What would you do for <a thing>?",
  "What's <person's> worst nightmare?",
  "What's the opposite of <>",
  "Without a doubt the <blankiest> animal is...",
  "You know what this <> needs? A whole lot less _____ and a whole lot more _____!",
  "<> is all fun and game until someone...",
  "First order of business when <> is elected president:",
  "In a pinch, _____ is a perfect substitute for <>",
  "<Person's> go to password:",
  "Why am I always so <>?",
  "<>? More like ________, am I right?",
  "Do you ever see a <> and just think...",
  "The first 4 words you say to your <>",
  "<person> likes to start each day by...",
  "I'll never go back to <>, not after...",
]

