import {useCallback} from "react"
import { gql, useMutation} from '@apollo/client';


export default function useRemovePlayer(params){
  const {stateRef, checkForErrors} = params;
  
  const onCompleted = useCallback(
    function onCompleted({removePlayer:res}){
      if (checkForErrors(res)) return;
    },
    [checkForErrors]
  )

  const [mutate] = useMutation(GQL, {onCompleted});

  const callback = useCallback(
    function removePlayer(playerId){
      const {room} = stateRef.current;
      const variables = {
        roomId:room.id,
        playerId,
      }
      return mutate({variables})
    },
    [mutate, stateRef]
  )

  return callback
}

const GQL = gql`
  mutation removePlayer(
    $roomId: ID!,
    $playerId: ID!
  ) {
    removePlayer(
      roomId: $roomId,
      playerId: $playerId
    ) {
      playerId
    }
  }
`;