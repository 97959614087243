import {useCallback} from "react"
import { gql, useMutation} from '@apollo/client';

export default function useResolveResponseVote(params){
  const {stateRef, checkForErrors} = params;
  
  const onCompleted = useCallback(
    function onCompleted({canaryResolvePrompt:res}){
      if (checkForErrors(res)) return;
    },
    [checkForErrors]
  )

  const [mutate] = useMutation(GQL, {onCompleted});

  return useCallback(
    function resolveIntro(){
      const {viewProps, isHost} = stateRef.current;
      if (!isHost) return;
      const variables = {
        promptId: viewProps.prompt.id,
      }
      return mutate({variables})
    },
    [mutate, stateRef]
  )
}

const GQL = gql`
  mutation canaryResolvePrompt($promptId: ID!) {
    canaryResolvePrompt(promptId: $promptId) {
      promptId
    }
  }
`;