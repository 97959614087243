import React, {useState, useEffect} from "react";

import { syncRandomItemWithSeed } from "common/utils";
import { celebrateGifs, countdownGifs } from "../context/gifs";
import useViewContext from "../context/context";


const VIEWS = {
  WAIT: "wait",
  GO: "go",
}

export default function IntroView(){
  const {resolveIntro, gameId} = useViewContext();

  const [seconds, setSeconds] = useState(3);
  
  const view = seconds > 0 ? VIEWS.WAIT : VIEWS.GO;

  useEffect(()=>{
    if (seconds == -3 ){
      resolveIntro();
    }
  }, [seconds])

  useEffect(()=>{
    const interval = setInterval(() => {
      setSeconds(current => current - 1)
    }, 1000);
    return () => clearInterval(interval);
  },[setSeconds])
  
  return (
    <div className="countview-view">
      <div style={{ opacity: view == VIEWS.WAIT ? 1 : 0 }}>
        <Wait seconds={seconds} gameId={gameId}/>
      </div>
      <div style={{ opacity: view == VIEWS.GO ? 1 : 0 }}>
        <Go gameId={gameId}/>
      </div>
    </div>
  )
}

function Wait({seconds, gameId}){

  const gifURL = syncRandomItemWithSeed(countdownGifs, gameId);
  return (
    <div
      className="full-screen-image"
      style={{ backgroundImage: `url(${gifURL})` }}
    >
      <div className="big-center-text">
        <div className="smaller-text">LIVE IN:</div>
        <div>{seconds}</div>
      </div>
    </div>
  )
}

function Go({gameId}){
  const gifURL = syncRandomItemWithSeed(celebrateGifs, gameId);

  return (
    <div
      className="full-screen-image"
      style={{ backgroundImage: `url(${gifURL})` }}
      >
      <div className="big-center-text">
        <div className="smaller-text text-top">HERE WE</div>
        <div className="text-bottom">GO!</div>
      </div>
    </div>
  )
}