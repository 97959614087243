import React from "react"

import useViewContext from "../context/context";

import { Header, VoteOption} from "../Components/layout";

export default function PromptVoteView(){
  const {viewProps, api, player} = useViewContext()
  const {prompt_set} = viewProps;

  function castVote(promptId){
    api.createPromptVote(promptId);
  }

  const filtered_prompts = prompt_set.filter(prompt => prompt.player.id != player.id)
  
  return (
    <>
      <Header text="Give some love to your favorite prompt!"/>
      {filtered_prompts.map((prompt) => (
        <VoteOption
          key={prompt.id}
          id={prompt.id}
          text={prompt.text}
          onSelect={castVote}
        />
      ))}
    </>
  );
}
