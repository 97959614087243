import React from "react";

import useViewContext from "../context/context";
import {shutoutGifs} from "../context/gifs";
import { syncRandomItemWithSeed } from "common/utils";

export default function ShutOut(){
    const {viewProps} = useViewContext()
    const {winner_id, is_shut_out, prompt} = viewProps;
  
    if (!winner_id || !is_shut_out) return null;
    const screenGif1 = syncRandomItemWithSeed(shutoutGifs, prompt.id);
    return (
      <div className={`shutout`}>
        <div
          className="screen-1 full-screen-image"
          style={{ backgroundImage: `url(${screenGif1})` }}
        >
          <div className="big-center-text">
            <div>SHUT</div>
            <div>OUT!</div>
          </div>
        </div>
      </div>
    );
  }
  
