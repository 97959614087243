import { gql, useSubscription} from '@apollo/client';
import useViewContext from '../context';
import { ROOM_BASE } from "./frags";


export default function useRoomSubscription(){
  const {dispatch, state} = useViewContext()

  function onSubscriptionData({subscriptionData}){
    const {room} = subscriptionData?.data?.roomStateChange
    dispatch({
      type:"updateRoomState",
      payload:{room}
    })
  }
  
  useSubscription(ROOM_STATE_SUBSCRIPTION, {
    variables: {roomId:state.room.id},
    onSubscriptionData
  })
}

const ROOM_STATE_SUBSCRIPTION = gql`
  subscription onRoomStateChange($roomId: ID!) {
    roomStateChange(roomId: $roomId) {
      room {
        ...RoomBase
      }
    }
  }
  ${ROOM_BASE}
`;
