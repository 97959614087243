
export default function reducer(state, action) {
  const { type, payload } = action;
  return reducerFunctions[type](state, payload);
}

function updateState(state, payload){
  return {
    ...state,
    ...payload,
  } 
}


function updateGameState(state, payload){
  const {
    view,
    viewKey,
    viewProps,
    roundNumber,
    errors,
  } = payload;

  return {
    ...state,
    view,
    viewKey,
    viewProps,
    roundNumber,
    errors,
  } 
}


const reducerFunctions = {
  updateState,
  updateGameState,
}