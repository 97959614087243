import React from "react"

import usePlayerContext from "player/context/context"

import usePlayerSubscription from "./context/api/usePlayerSubscription";
import { SUB_VIEWS } from "./context/constants";
import useViewContext, { ViewProvider } from "./context/context";
import WaitView from "./WaitView/WaitView";
import PromptView from "./PromptView/PromptView";
import ResponseView from "./ResponseView/ResponseView";
import ResponseVoteView from "./ResponseVoteView/ResponseVoteView";
import PromptVoteView from "./PromptVoteView/PromptVoteView";
import LayoutWrapper from "./layout";

export default function WrappedView(){
  const {room, player} = usePlayerContext()
  return (
    <ViewProvider gameId={room.gameId} player={player}>
      <View />
    </ViewProvider>
  );
}

function View() {
  const {view, viewKey} = useViewContext()
  const SubView = SUB_VIEWS_COMPONENTS[view] || WaitView;
  
  usePlayerSubscription()

  return (
    <LayoutWrapper>
      <SubView key={viewKey}/>
      <Errors />
    </LayoutWrapper>
  );
}

const SUB_VIEWS_COMPONENTS = {
  [SUB_VIEWS.WAIT]: WaitView,
  [SUB_VIEWS.PROMPT]: PromptView,
  [SUB_VIEWS.RESPONSE]: ResponseView,
  [SUB_VIEWS.RESPONSE_VOTE]: ResponseVoteView,
  [SUB_VIEWS.PROMPT_VOTE]: PromptVoteView,
}

function Errors() {
  const { errors } = useViewContext();
  if (!errors.length) return null;

  return (
    <div>
      Errors:
      <ul>
        {errors.map(error=>(
          <li>{error}</li>
        ))}
      </ul>
    </div>
  )
}