export const countdownGifs = [
  "https://media.giphy.com/media/3o7TKMBfpytWA3eaQ0/giphy.gif",
  "https://media.giphy.com/media/l3vR9h0idIAxDDWZW/giphy.gif",
  "https://media.giphy.com/media/26AHJ6vlDNebEv37q/giphy.gif",
  "https://media.giphy.com/media/ZgjCn4ZfpekWA/giphy.gif",
  "https://media.giphy.com/media/10rZiqsWfq8c7u/giphy.gif",
  "https://media.giphy.com/media/xT1XGvuqziuV60COVq/giphy.gif",
  "https://media.giphy.com/media/YY5zqgu0zUwve/giphy.gif",
  "https://media.giphy.com/media/viEM13XhzGgA8/giphy.gif",
  "https://media.giphy.com/media/fwiv7a4VIshY9BJM7P/giphy.gif",
  "https://media.giphy.com/media/qmbw7BPOcGLHZSB5Jn/giphy.gif",
  "https://media.giphy.com/media/ZezB6UeuJ4JVe/giphy.gif",
  "https://media.giphy.com/media/l1J9S5ngBrEi0JTCo/giphy.gif",
  "https://media.giphy.com/media/oYtVHSxngR3lC/giphy.gif",
  "https://media.giphy.com/media/HzjNv9lL25fNe/giphy.gif",
  "https://media.giphy.com/media/rMjPemIIS2Nig/giphy.gif",
  "https://media.giphy.com/media/PvKlwYTiKATRe/giphy.gif",
  "https://media.giphy.com/media/TEsHBsigscRKE/giphy.gif",
  "https://media.giphy.com/media/gZ0GEz2HefV4s/giphy.gif",
  "https://media.giphy.com/media/brHaCdJqCXijm/giphy.gif",
  "https://media.giphy.com/media/7eAvzJ0SBBzHy/giphy.gif",
  "https://media.giphy.com/media/bWS1Vh9mVkcZq/giphy.gif",
  "https://media.giphy.com/media/6YCelxMJo0txK/giphy.gif",
  "https://media.giphy.com/media/xWCHFRmGyO8KczXYII/giphy.gif",
  "https://media.giphy.com/media/xTiN0tFPPfKXKo9TsA/giphy.gif",
  "https://media.giphy.com/media/GBvkxysAR8Svm/giphy.gif",
  "https://media.giphy.com/media/xTiTnID0rYnyqpmLM4/giphy.gif",
  "https://media.giphy.com/media/26n6WywJyh39n1pBu/giphy.gif",
  "https://media.giphy.com/media/Ll8iJ5gbx5GpAJRbHK/giphy.gif",
  "https://media.giphy.com/media/10NFiiV19LK4MM/giphy.gif",
  "https://media.giphy.com/media/yR4xZagT71AAM/giphy.gif",
  "https://media.giphy.com/media/6pUBXVTai18Iw/giphy.gif",
  "https://media.giphy.com/media/14uRnBOrCwkOMU/giphy.gif",
  "https://media.giphy.com/media/BhQ3kAH4dDHuU/giphy.gif",
  "https://media.giphy.com/media/AVBo5eqFXd3SU/giphy.gif",
  "https://media.giphy.com/media/mFZ7zYki7WZEwyDWaU/giphy.gif",
  "https://media.giphy.com/media/LvaswPm6IHn44/giphy.gif",
  "https://media.giphy.com/media/8hZGJeWHVSZYCmGIAF/giphy.gif",
  "https://media.giphy.com/media/xTiN0m5rI2WK4xRJRe/giphy.gif",
  "https://media.giphy.com/media/bpnpWCgqKCgX6/giphy.gif",
  "https://media.giphy.com/media/3o6fIVfpq8UZCuWDV6/giphy.gif",
  "https://media.giphy.com/media/d8QMaqv4F97cRPkHgf/giphy.gif",
  "https://media.giphy.com/media/12SXVd8bmXdSg0/giphy.gif",
  "https://media.giphy.com/media/H5xAtM1jrhdMzWIAdI/giphy.gif",
  "https://media.giphy.com/media/q4sdF9tchap6E/giphy.gif",
  "https://media.giphy.com/media/d2dHfjIyLY2sIbyGK3/giphy.gif",
];

export const celebrateGifs = [
  "https://media.giphy.com/media/3osxYo2qGQU69fwvWE/giphy.gif",
  "https://media.giphy.com/media/3o7WTAyFnxCHlg00bS/giphy.gif",
  "https://media.giphy.com/media/IxVDJBhPqhI3u/giphy.gif",
  "https://media.giphy.com/media/j19IGksJHFLz2/giphy.gif",
  "https://media.giphy.com/media/igl5FTLhB2BcA/giphy.gif",
  "https://media.giphy.com/media/tAFTDSmPMQwBa/giphy.gif",
  "https://media.giphy.com/media/3o7WTCC8dnaS5hVt5K/giphy.gif",
  "https://media.giphy.com/media/xT9DPlkdctMrSYWbok/giphy.gif",
  "https://media.giphy.com/media/dzinXfTdzh6M0/giphy.gif",
  "https://media.giphy.com/media/7fLvK10wH1Mpa/giphy.gif",
  "https://media.giphy.com/media/hZjkueCUKxUywxYXds/giphy.gif",
  "https://media.giphy.com/media/YvpSuHgaMO9G0/giphy.gif",
  "https://media.giphy.com/media/HgKoTCwLbnAS59xXny/giphy.gif",
  "https://media.giphy.com/media/7TM8kWy04HzcA/giphy.gif",
  "https://media.giphy.com/media/v0A5YZHW4ARFu/giphy.gif",
  "https://media.giphy.com/media/vMEjhlxsBR7Fe/giphy.gif",
  "https://media.giphy.com/media/5xrkJe3IJKSze/giphy.gif",
  "https://media.giphy.com/media/dkGhBWE3SyzXW/giphy.gif",
  "https://media.giphy.com/media/Ge86XF8AVY1KE/giphy.gif",
  "https://media.giphy.com/media/DYH297XiCS2Ck/giphy.gif",
  "https://media.giphy.com/media/CAxbo8KC2A0y4/giphy.gif",
  "https://media.giphy.com/media/3bLhqnqJjMNtC/giphy.gif",
  "https://media.giphy.com/media/ZdFxoPhIS4glG/giphy.gif",
  "https://media.giphy.com/media/ycWKiijFxvSXC/giphy.gif",
  "https://media.giphy.com/media/JSvgjnJawWYqZJGsVU/giphy.gif",
  "https://media.giphy.com/media/KiXl0vfc9XIIM/giphy.gif",
  "https://media.giphy.com/media/l8XYZYdlOHSrS/giphy.gif",
  "https://media.giphy.com/media/yoJC2COHSxjIqadyZW/giphy.gif",
  "https://media.giphy.com/media/sRFym5lssgcTFrMSac/giphy.gif",
  "https://media.giphy.com/media/ZbUIi5RuPahtCN90OL/giphy.gif",
  "https://media.giphy.com/media/gpXfKa9xLAR56/giphy.gif",
  "https://media.giphy.com/media/8rFgzA7aBR0aomiOMj/giphy.gif",
  "https://media.giphy.com/media/g0dgv1yJiQxjac4Sl6/giphy.gif",
  "https://media.giphy.com/media/raHMQ4nj2Ugx2/giphy.gif",
  "https://media.giphy.com/media/3oz8xH9l1ci0ZJFawM/giphy.gif",
  "https://media.giphy.com/media/vZ3IYG8uDUxY4/giphy.gif",
];

export const waitGifs = [
  "https://media.giphy.com/media/tXL4FHPSnVJ0A/giphy.gif",
  "https://media.giphy.com/media/myPdoRAlad0J2/giphy.gif",
  "https://media.giphy.com/media/xf20D8HzvTQzu/giphy.gif",
  "https://media.giphy.com/media/7zrZAg61HEsYDmOvto/giphy.gif",
  "https://media.giphy.com/media/3o7TKxOhkp8gO0LXMI/giphy.gif",
  "https://media.giphy.com/media/ZcnwOpPTw9Ucw/giphy.gif",
  "https://media.giphy.com/media/cNBh0QlyDdVFC/giphy.gif",
];

export const tieGifs = [
  "https://media.giphy.com/media/F8HszZRmY3xjW/giphy.gif",
  "https://media.giphy.com/media/3o6UBlCOoOIPIiS652/giphy.gif",
  "https://media.giphy.com/media/DHLXiQ5bHLT8s/giphy.gif",
  "https://media.giphy.com/media/l2R03nrt5LDkpc6VG/giphy.gif",
  "https://media.giphy.com/media/Xa3stf0D8ULAq3WCaT/giphy.gif",
  "https://media.giphy.com/media/E6yiq91DM75hC/giphy.gif",
  "https://media.giphy.com/media/xT9IgJ7tAgL6TUwY5W/giphy.gif",
  "https://media.giphy.com/media/451jzkxoJGIWBgC1Xt/giphy.gif",
  "https://media.giphy.com/media/YToaUGI1WM144/giphy.gif",
  "https://media.giphy.com/media/Lu1Ua0rCRFpLO/giphy.gif",
  "https://media.giphy.com/media/42YlR8u9gV5Cw/giphy.gif",
  "https://media.giphy.com/media/3o6nUQgYhSBUAXBOQo/giphy.gif",
  "https://media.giphy.com/media/26BRxWm2c7wWLnMbu/giphy.gif",
  "https://media.giphy.com/media/0eFf0BSbESSrMuNKkZ/giphy.gif",
  "https://media.giphy.com/media/yOhgNO9YsmdsQ/giphy.gif",
  "https://media.giphy.com/media/lPkgVnlOTin5e/giphy.gif",
  "https://media.giphy.com/media/4esrzplOvKkE0/giphy.gif",
  "https://media.giphy.com/media/32qAeAZG3iFjy/giphy.gif",
  "https://media.giphy.com/media/r963bkXmjEV4Q/giphy.gif",
  "https://media.giphy.com/media/3o6Ei2MKdSXo8XZqlW/giphy.gif",
  "https://media.giphy.com/media/oV4DOTSXRhRMA/giphy.gif",
  "https://media.giphy.com/media/1Y5x5Uq22Tlx6/giphy.gif",
  "https://media.giphy.com/media/LmpHsHFG1ZoRy/giphy.gif",
  "https://media.giphy.com/media/d9WRhx9qErqCY/giphy.gif",
  "https://media.giphy.com/media/l46CcVsDKp97gSDhm/giphy.gif",
  "https://media.giphy.com/media/xT9DPCJ1uw5uf56sGA/giphy.gif",
  "https://media.giphy.com/media/Ke3CM1NVkULWo/giphy.gif",
  "https://media.giphy.com/media/3o85xAlXSDMvtuDaBq/giphy.gif",
  "https://media.giphy.com/media/sy5ly1gNlmIdG/giphy.gif",
  "https://media.giphy.com/media/I3EsiEPZWgpqg/giphy.gif",
  "https://media.giphy.com/media/wrzf9P70YWLJK/giphy.gif",
  "https://media.giphy.com/media/13oH6nnW52LUFG/giphy.gif",
  "https://media.giphy.com/media/CO2qotWn78ZrO/giphy.gif",
  "https://media.giphy.com/media/xT0BKiDavTuCSw3PHy/giphy.gif",
  "https://media.giphy.com/media/10LKovKon8DENq/giphy.gif",
  "https://media.giphy.com/media/120jXUxrHF5QJ2/giphy.gif",
  "https://media.giphy.com/media/26ufgSwMRqauQWqL6/giphy.gif",
  "https://media.giphy.com/media/5wWf7GW1AzV6pF3MaVW/giphy.gif",
  "https://media.giphy.com/media/r2BtghAUTmpP2/giphy.gif",
  "https://media.giphy.com/media/fLK0eUlYZoB6E/giphy.gif",
  "https://media.giphy.com/media/fm4WhPMzu9hRK/giphy.gif",
  "https://media.giphy.com/media/qNbZIIdTXLTHy/giphy.gif",
];

export const fightGifs = [
  "https://media.giphy.com/media/1exlkOu6qT8di/giphy.gif",
  "https://media.giphy.com/media/SYdTdhrw9caya0X8jH/giphy.gif",
  "https://media.giphy.com/media/FYOxEpdW8K1H2/giphy.gif",
  "https://media.giphy.com/media/l2JeiHzkYVdXsMbKg/giphy.gif",
  "https://media.giphy.com/media/wtn4d7kcnV5PRt2hcl/giphy.gif",
  "https://media.giphy.com/media/5qF688fc8X2XCQ4R2L/giphy.gif",
  "https://media.giphy.com/media/l0K469pnf9MH9ktDa/giphy.gif",
  "https://media.giphy.com/media/tV0HkQju9zHR6/giphy.gif",
  "https://media.giphy.com/media/q3uDB1eaA2jEk/giphy.gif",
  "https://media.giphy.com/media/3GCeasnPeeImI/giphy.gif",
  "https://media.giphy.com/media/PTsRi4KmPJkOc/giphy.gif",
  "https://media.giphy.com/media/mgPzH4Gj3YrBK/giphy.gif",
  "https://media.giphy.com/media/xT9IghiTBosULziuEo/giphy.gif",
  "https://media.giphy.com/media/3oEdv3VDEIbMQX8N3i/giphy.gif",
  "https://media.giphy.com/media/lueaDWxOz0LNS/giphy.gif",
  "https://media.giphy.com/media/3ohc0QQkTH6YK8g4zS/giphy.gif",
  "https://media.giphy.com/media/8Xks8K6RDd4Jy/giphy.gif",
  "https://media.giphy.com/media/hYxfXTcDzwzUk/giphy.gif",
  "https://media.giphy.com/media/nxG4i6YuQnHzi/giphy.gif",
  "https://media.giphy.com/media/SsZViiaRCjgp8fVexU/giphy.gif",
  "https://media.giphy.com/media/lgcaIKboeo8ZW/giphy.gif",
  "https://media.giphy.com/media/14rWmwSwdZokSI/giphy.gif",
  "https://media.giphy.com/media/HOzvlnTVPMBj2/giphy.gif",
  "https://media.giphy.com/media/Gfza8p6JjrrEI/giphy.gif",
  "https://media.giphy.com/media/w78bt945MMoxKsbECH/giphy.gif",
  "https://media.giphy.com/media/JqEB4KkitGwhPRtC7G/giphy.gif",
  "https://media.giphy.com/media/Pkny92clCrzva/giphy.gif",
  "https://media.giphy.com/media/X0YkzoS6UqVfa/giphy.gif",
  "https://media.giphy.com/media/3o84sI0BaoeyM5PZQY/giphy.gif",
  "https://media.giphy.com/media/132WJVgKFEUyuk/giphy.gif",
  "https://media.giphy.com/media/iYssqHbOcvL20/giphy.gif",
  "https://media.giphy.com/media/fX8771PO1eATJz6r4R/giphy.gif",
  "https://media.giphy.com/media/3og0IHyZMxZNkNOWT6/giphy.gif",
  "https://media.giphy.com/media/xW4DdeXLofNm0/giphy.gif",
];

export const shutoutGifs = [
  "https://media.giphy.com/media/MorhLRl96DcRO/giphy.gif",
  "https://media.giphy.com/media/7g8qU5SESGzyE/giphy.gif",
  "https://media.giphy.com/media/zEU2uwmialC4U/giphy.gif",
  "https://media.giphy.com/media/52VjAeGgj78GY/giphy.gif",
  "https://media.giphy.com/media/BNFKn8ChNkcco/giphy.gif",
  "https://media.giphy.com/media/INdsz6tW8KRJS/giphy.gif",
  "https://media.giphy.com/media/fMfVV4yJCsBcA/giphy.gif",
  "https://media.giphy.com/media/3o7WTwcO5cLAVL5Z7O/giphy.gif",
  "https://media.giphy.com/media/Hidva3NC6BulW/giphy.gif",
  "https://media.giphy.com/media/s6EYTqTRqujIY/giphy.gif",
  "https://media.giphy.com/media/kmOoBqGnQwls4/giphy.gif",
  "https://media.giphy.com/media/F3lCmld8V5Au4/giphy.gif",
  "https://media.giphy.com/media/nIM0pmYCPZ11S/giphy.gif",
  "https://media.giphy.com/media/WTlH9XMLIAD4I/giphy.gif",
  "https://media.giphy.com/media/iVpy4wkWFHQdi/giphy.gif",
  "https://media.giphy.com/media/xT1XGPm6RZ01MvmtNK/giphy.gif",
  "https://media.giphy.com/media/3ohzgDp9WdFFILQOYw/giphy.gif",
  "https://media.giphy.com/media/4WF7RDfAFJtvIWpfOK/giphy.gif",
  "https://media.giphy.com/media/6rTQC2UiX9AOI/giphy.gif",
  "https://media.giphy.com/media/fMzw8byEJVmm4Swpme/giphy.gif",
  "https://media.giphy.com/media/l46CqLVMWzaJUFPLW/giphy.gif",
  "https://media.giphy.com/media/3ofT5I53iCdlGUjzt6/giphy.gif",
  "https://media.giphy.com/media/KYElw07kzDspaBOwf9/giphy.gif",
  "https://media.giphy.com/media/3rUbeDiLFMtAOIBErf/giphy.gif",
  "https://media.giphy.com/media/l49JWPbCDpd0Xm5pu/giphy.gif",
  "https://media.giphy.com/media/nx9tH5eRHVq9y/giphy.gif",
  "https://media.giphy.com/media/sFZ7jXF6KHu8g/giphy.gif",
  "https://media.giphy.com/media/xT39Dps0ZuuZvcqeek/giphy.gif",
  "https://media.giphy.com/media/l41lZccR1oUigYeNa/giphy.gif",
  "https://media.giphy.com/media/KXY5lB8yOarLy/giphy.gif",
  "https://media.giphy.com/media/MzId7vT7YOUzm/giphy.gif",
  "https://media.giphy.com/media/l1KUvnGPmDLIzGAEw/giphy.gif",
  "https://media.giphy.com/media/lGuYeSHJAYMrS/giphy.gif",
  "https://media.giphy.com/media/tD0LVegS0nFII/giphy.gif",
  "https://media.giphy.com/media/kQg7fQMvVD5Ha/giphy.gif",
  "https://media.giphy.com/media/l0IycoSGegzwYUkc8/giphy.gif",
  "https://media.giphy.com/media/eh71yNMhyTGq0zWWDi/giphy.gif",
  "https://media.giphy.com/media/yoJC2JaiEMoxIhQhY4/giphy.gif",
  "https://media.giphy.com/media/l3q2Z6S6n38zjPswo/giphy.gif",
  "https://media.giphy.com/media/o93GzkvoPlYME/giphy.gif",
  "https://media.giphy.com/media/msKNSs8rmJ5m/giphy.gif",
  "https://media.giphy.com/media/KQBB3QhDw46Uo/giphy.gif",
  "https://media.giphy.com/media/26BRrrJsJZr5FxApW/giphy.gif",
  "https://media.giphy.com/media/eNiirMdWRQdO/giphy.gif",
  "https://media.giphy.com/media/xTiQygnAR1O5jg3eJq/giphy.gif",
  "https://media.giphy.com/media/l3vRgWpnwbPEjXila/giphy.gif",
  "https://media.giphy.com/media/jLPjEjSVBGLi8/giphy.gif",
  "https://media.giphy.com/media/PK7IAHdB0l9mg/giphy.gif",
  "https://media.giphy.com/media/DVHrK100eH3J6/giphy.gif",
  "https://media.giphy.com/media/YqHMVSYXIMEiA/giphy.gif",
];

export const clapGifs = [
  "https://media.giphy.com/media/S72iiCxTYi2ubzbvKF/giphy.gif",
  "https://media.giphy.com/media/LoQsXCVlyGPfy/giphy.gif",
  "https://media.giphy.com/media/l0MYuyCJvMIZN5cTS/giphy.gif",
  "https://media.giphy.com/media/3oz8xNrYk4BkErDRSw/giphy.gif",
  "https://media.giphy.com/media/Hd3GXtH7xs1CU/giphy.gif",
  "https://media.giphy.com/media/1PMVNNKVIL8Ig/giphy.gif",
  "https://media.giphy.com/media/YJ5OlVLZ2QNl6/giphy.gif",
  "https://media.giphy.com/media/6oMKugqovQnjW/giphy.gif",
  "https://media.giphy.com/media/26tPplGWjN0xLybiU/giphy.gif",
  "https://media.giphy.com/media/JltOMwYmi0VrO/giphy.gif",
  "https://media.giphy.com/media/3o7abldj0b3rxrZUxW/giphy.gif",
  "https://media.giphy.com/media/fnK0jeA8vIh2QLq3IZ/giphy.gif",
  "https://media.giphy.com/media/qnOBmH70CGSVa/giphy.gif",
  "https://media.giphy.com/media/l3q2XhfQ8oCkm1Ts4/giphy.gif",
  "https://media.giphy.com/media/WPJDGY9K5AVa0/giphy.gif",
  "https://media.giphy.com/media/7rj2ZgttvgomY/giphy.gif",
  "https://media.giphy.com/media/m6pfYaB3mcR5m/giphy.gif",
  "https://media.giphy.com/media/l9Tllo1thElT5gvVOU/giphy.gif",
  "https://media.giphy.com/media/7Xmgrc6ty9XlZU6h2M/giphy.gif",
  "https://media.giphy.com/media/3o72FcJmLzIdYJdmDe/giphy.gif",
  "https://media.giphy.com/media/10spcFioEOY7zG/giphy.gif",
  "https://media.giphy.com/media/Jf4FBs6JZhvKU/giphy.gif",
  "https://media.giphy.com/media/el0ou7lRocwSI/giphy.gif",
  "https://media.giphy.com/media/1Javkvna0YQp2/giphy.gif",
  "https://media.giphy.com/media/xT1R9R2sCTWQ2A1fs4/giphy.gif",
  "https://media.giphy.com/media/QW5nKIoebG8y4/giphy.gif",
  "https://media.giphy.com/media/l4HnY84pKDQCwm7II/giphy.gif",
  "https://media.giphy.com/media/UzbyxavDrx31K/giphy.gif",
  "https://media.giphy.com/media/y8Mz1yj13s3kI/giphy.gif",
  "https://media.giphy.com/media/xXuQz6usY7gKk/giphy.gif",
  "https://media.giphy.com/media/31lPv5L3aIvTi/giphy.gif",
  "https://media.giphy.com/media/BlVnrxJgTGsUw/giphy.gif",
  "https://media.giphy.com/media/12P29BwtrvsbbW/giphy.gif",
  "https://media.giphy.com/media/GBhju13tiVB60/giphy.gif",
  "https://media.giphy.com/media/CBOFQfzFKKiha/giphy.gif",
  "https://media.giphy.com/media/KzTO9ClEsxQSA/giphy.gif",
  "https://media.giphy.com/media/26tkmwzpL7hyioxri/giphy.gif",
  "https://media.giphy.com/media/YP258EkezKv5RSPGRI/giphy.gif",
  "https://media.giphy.com/media/1Syi8XSAkiFupEty1u/giphy.gif",
  "https://media.giphy.com/media/YFis3URdQJ6qA/giphy.gif",
];
  