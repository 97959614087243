import React from "react";
import { Link } from "react-router-dom";
import "./home.scss"

import { URLS } from "common/internal";
import useFlicker from "common/hooks/useFlicker"

const GAME_SCREEN = "Room Screen";
const PLAYER_SCREEN = "Player Controller";
const ROOM_CODE = "Room Code";

export default function Home() {

  const style = useFlicker({}, {
    backgroundImage: `url(https://media.giphy.com/media/l3fzOCIuFxXpmHe2A/giphy.gif)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }, 200, 500)

  return (
    <div className="app" style={style}>
      <div className="view-container width-limit"  >
        <h1>Yallbetcha!</h1>
        <Button 
          text={`📱 ${PLAYER_SCREEN}`}
          url={URLS.PLAYER}
          gif="https://media.giphy.com/media/4Z3URvjmDiZF0VHWcU/giphy.gif"
        />
        <Button
          text={`📺 ${GAME_SCREEN}`}
          url={URLS.ROOM}
          gif="https://media.giphy.com/media/26gspMSVnAI4K92so/giphy.gif"
        />
        <Instructions/>
      </div>
    </div>
  );
}


function Button({text, gif, url}){

  const style = useFlicker({
    background:"#4fc0ff",
  }, {
    backgroundImage: `url(${gif})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }, 30, 100)

  return (
    <div>
      <Link
        className="link-button"
        to={url}
        style={style}
      >
        {text}
      </Link>
    </div>
  )
}

function Instructions(){
  return (
    <div className="instructions-container">
      <h2>Howdy You Guys! New around here?</h2>
      <div className="instructions">
        <GamePlay/>
        <Setup/>
        <Feedback/>
      </div>
    </div>
  )
}

function VariableName({text}){
  return <span className="proper-noun">{text}</span>
}

function GamePlay(){
  return (
    <>
    <h3>Game Play</h3>
    <p>
      Each player writes a prompt on their device. Something like "Jaws would be have been much funnier if...".
      <br/>
      Then each player responds to two other players' prompts on their device.
      <br/>
      Then each prompt and its responses are displayed on the <VariableName text={GAME_SCREEN}/>. Everyone casts a vote for their favorite.
      Once the votes are in, the results are revealed and players receive points for each vote their response gets.
      <br/>
      Three rounds of that and you will have definitive proof who is funniest person in your group.
    </p>
    </>
  )
}

function Setup(){
  return (
    <>
    <h3>Setup</h3>
    <p>
      First, someone (probably you) creates a new <VariableName text={GAME_SCREEN}/>. Then ALL
      players (including you) join the game with a <VariableName text={PLAYER_SCREEN}/> using the
      {" "}<VariableName text={ROOM_CODE}/>. If you guys are in the same space, yall only need one{" "}
      <VariableName text={GAME_SCREEN}/>. If yall video chatting, just share your <VariableName text={GAME_SCREEN}/>{" "}
      with everyone. Otherwise, jump on a phone call or something and have
      each household start their own <VariableName text={GAME_SCREEN}/> and join yours using your{" "}
      <VariableName text={ROOM_CODE}/>.
      <br />
      Once everyone is in, start the game and it will explain the rest!
    </p>
    </>
  )
}

function Feedback(){
  return (<>
    <h3>Have a strong feeling about the game?</h3>
    <p>practicalgameco@gmail.com</p>
  </>
  )
}